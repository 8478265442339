import React, { useState, useMemo } from "react";
import { GlobalStyles } from "../../../styles/global";

const expensiveOperation = (oa: number, ob: number) => {
  alert(`expensiveOperation(${oa}, ${ob})`);
  return oa + ob;
};

const ExpensiveComponent = () => {
  const [callCount, setCallCount] = useState(0);
  const a = 100;
  const b = 250;
  const memoCallback = () => expensiveOperation(a, b);
  const memoDependencies = [a, b];
  const memoizedValue = useMemo(memoCallback, memoDependencies);
  return (
    <div>
      <button onClick={(e) => setCallCount(callCount + 1)}>Count</button>
      <p>Count: {callCount}</p>
      <p>Memoized Value: {memoizedValue}</p>
    </div>
  );
};

export const MemoDemo = () => {
  const [showDemo, setShowDemo] = useState(false);
  const demoStyle = {
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#aaa",
  };
  return (
    <div>
      <GlobalStyles></GlobalStyles>
      {showDemo ? (
        <ExpensiveComponent></ExpensiveComponent>
      ) : (
        <div style={demoStyle}>
          <button onClick={(e) => setShowDemo(true)}>
            Click to Enable Demo
          </button>
        </div>
      )}
    </div>
  );
};

export default MemoDemo;
