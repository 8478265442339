import { createGlobalStyle } from "styled-components";

const ThemeDark = {
  background: "#172833",
  color: "#fafafa",
};

export const GlobalStyles = createGlobalStyle`
  :root {
    font-size: 16px;
    scroll-behavior: smooth;

    --font-family-heading: 'Montserrat', sans-serif;
    --font-family-body: 'Karla', sans-serif;

    --font-size-mobile: 1.2rem;
    --font-size-tablet: 1.125rem;
    --font-size-desktop: 1.25rem;

    --content-width-tablet: 85%;
    --content-width-desktop: 90%;
    --content-width-max: 1280px;

    --brand: #e9573f;
    --brand-complementary: #3f8be9;

  }

  body {
    font-size: var(--font-size-mobile);
    line-height: 1.8;
    text-rendering: optimizeLegibility;
    margin: 0;
    font-family: var(--font-family-body);
    text-rendering: optimizeLegibility;
  }

  @media (min-width: 600px) {
    body {
      font-size: var(--font-size-tablet);
    }
  }

  @media (min-width: 1024px) {
    body {
      font-size: var(--font-size-desktop);
    }
  }

  p, li {
    a {
      font-weight: bold;
      color: var(--brand);
      text-decoration: none;
      box-shadow: 0 3px currentColor;
      transition: color .3s ease;

      &:hover,
      &:focus {
        color: var(--brand-complementary);
      }
    }
  }

  p a:hover,
  p a:focus {
    color: #3f8be9;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: var(--font-family-heading);
  }

  h1 {
    font-weight: 900;
    text-transform: uppercase;
    line-height: 1.2;
    margin: 0 0 1rem;
  }

  hr {
    border: none;
    width: 100%;
    height: 1px;
    background: #ddd;
    margin: 2rem 0;
  }
`;
